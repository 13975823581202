.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid white;
  background: rgb(6 182 212); /* Tailwind'in rengi */
  cursor: pointer;
}

.scroll-container::-webkit-scrollbar {
  width: 0; /* Scroll bar genişliğini sıfırla */
  background: transparent; /* Arka planı şeffaf yap */
} 

/* Firefox için scroll barı gizle */
.scroll-container {
  scrollbar-width: none;
  -ms-overflow-style: none; 
  scroll-behavior: smooth; /* Kaydırma animasyonlarını yumuşatır */
/* -webkit-overflow-scrolling: touch; */
}
